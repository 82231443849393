//import './../css/estilos.css';

//var $ = require('jquery/src/core');
//require('jquery/src/core/init');
//require('jquery/src/manipulation');

//require('jquery/src/ajax');
//require('jquery/src/ajax/xhr');
//require('jquery/src/css');

(function ($) {
    var CookieManager = {
        idioma: "es",
        bannerInteractionTrue: "banner_interaction:true",
        bannerInteractionFalse: "banner_interaction:false",
        acceptedAdsCookieFragment: "ad_storage:true, ad_personalization:true, ad_user_data:true",
        initialCookieValue: function () {
            return this.bannerInteractionFalse + ", ad_storage:false, ad_personalization:false, ad_user_data:false, analytics_storage:false";
        },
        acceptedCookieValue: function () {
            return this.bannerInteractionTrue + ", " + this.acceptedAdsCookieFragment + ", analytics_storage:true";
        },
        rejectedCookieValue: function () {
            return this.bannerInteractionTrue + ", ad_storage:false, ad_personalization:false, ad_user_data:false, analytics_storage:false";
        },
        leyCookies: function () {
            if (this.idioma === "es") {
                return '<style>.btn-cookies{background:none;color:rgb(228,0,43);margin-bottom:1rem;border-radius:5px;border: 2px solid rgb(228,0,43);width:auto;display:inline-block;box-shadow:none !important;} .btn-cookies:hover{color:#b00043;border:2px solid #b00043;} .no-scroll{overflow:hidden;}</style><div id="cookies-overlay" style="position:fixed;top:0;left:0;width:100%;height:100%;background-color:rgba(0, 0, 0, 0.7);z-index: 9998;"></div><div id="cookies" style="z-index: 99999;position:sticky;width:100%;bottom:0px;margin:0; display:none; line-height:1.3; background:#efefef; padding:10px;"><div style="text-align:left;margin-left:auto;margin-right:auto;max-width:1080px;"><p>Human Level Communications S.L.U. utiliza cookies propias y de terceros para finalidades analíticas mediante el análisis del tráfico web, ofrecer funciones de redes sociales y mostrarle publicidad personalizada en base a un perfil elaborado a partir de sus hábitos de navegación. Para más información, puedes consultar nuestra <a href="/informacion-sobre-cookies" target="_blank" style="color:#ba1c1e; text-decoration:underline;">política de cookies</a>.  Puedes aceptar todas las cookies mediante el botón "Aceptar", rechazar las cookies no esenciales mediante el botón "Rechazar" o puedes seleccionar qué cookies quieres aceptar pulsando en "Personalizar cookies".</p> <div><button class="btn btn-hlc" id="aceptarCookies" style="width:auto;display:inline-block;">Aceptar todas</button> <button class="btn btn-cookies" id="configurarCookies" data-toogle="modal" data-target="#cookiesModal">Personalizar cookies</button> <button class="btn btn-cookies" id="rechazarTodas">Rechazar</button> </div></div></div>';
            } else {
                return '<style>.btn-cookies{background:none;color:rgb(228,0,43);margin-bottom:1rem;border-radius:5px;border: 2px solid rgb(228,0,43);width:auto;display:inline-block;box-shadow:none !important;} .btn-cookies:hover{color:#b00043;border:2px solid #b00043;}</style><div id="cookies-overlay" style="position:fixed;top:0;left:0;width:100%;height:100%;background-color:rgba(0, 0, 0, 0.7);z-index: 9998;"></div><div id="cookies" style="z-index: 99999;position:sticky;width:100%;bottom:0px;margin:0; display:none; line-height:1.3; background:#efefef; padding:10px;"><div style="text-align:left;margin-left:auto;margin-right:auto;max-width:1080px;"><p>Human Level Communications S.L.U. uses its own and third-party cookies for analytical purposes by analyzing web traffic, offering social networking features and showing personalized advertising based on a profile developed from your browsing habits. For more information you can consult our <a href="/en/information-about-cookies" target="_blank" style="color:#ba1c1e; text-decoration:underline;">cookies policy</a>.  You can accept all cookies by clicking on the "Accept" button, reject non-essential cookies by clicking on the "Reject" button or you can select which cookies you want to accept by clicking on "Customize cookies".</p> <div><button class="btn btn-hlc" id="aceptarCookies" style="width:auto;display:inline-block;">Accept all</button> <button class="btn btn-cookies" id="configurarCookies" data-toogle="modal" data-target="#cookiesModal">Customize cookies</button> <button class="btn btn-cookies" id="rechazarTodas">Reject</button> </div></div></div>';
            }
        },

        modalCookies: function () {
            if (this.idioma === "es") {
                return (
                    '<div class="modal" id="cookiesModal" tabindex="-1" role="dialog" aria-hidden="true" style="overflow:auto;">' +
                    '<div class="modal-dialog" id="modalCookiesDialog" role="document">' +
                    '<div class="modal-content"><div class="modal-header"><h5 class="modal-title" id="exampleModalLabel">Personalizar Cookies</h5>' +
                    '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>' +
                    '<div class="modal-body">' +
                    '<div class="form-check"><input class="form-check-input" type="checkbox" id="cookiesFuncionales" checked disabled /> <label style="color:inherit;" class="form-check-label" for="cookiesFuncionales">Cookies funcionales</label>' +
                    '<p style="font-size:15px;">Son esenciales para que puedas usar un sitio web de la manera en que fue diseñado. Nos permiten, por ejemplo, recordar tus preferencias de idioma. Sin ellas, nuestro sitio web no podría funcionar correctamente ni proporcionar las funciones básicas que necesitas.</p>' +
                    "</div>" +
                    '<div class="form-check"><input class="form-check-input" type="checkbox" id="cookiesAnaliticas" /> <label class="form-check-label" for="cookiesAnaliticas">Cookies de analítica</label>' +
                    '<p style="font-size:15px;">Son aquéllas que, bien tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que le ofrecemos.</p>' +
                    "</div>" +
                    '<div class="form-check"><input class="form-check-input" type="checkbox" id="cookiesPubli" /> <label class="form-check-label" for="cookiesPubli">Cookies de publicidad</label>' +
                    '<p style="font-size:15px;">Son aquéllas cookies tratadas por terceros, que permiten gestionar de la forma más eficaz posible la oferta de los espacios publicitarios que hay en otras webs, adecuando el contenido del anuncio al contenido del servicio solicitado o al uso que realice de nuestra página web. Para ello, pueden analizar sus hábitos de navegación en Internet y pueden mostrarle publicidad relacionada con su perfil de navegación.</p>' +
                    "</div>" +
                    '<div class="modal-footer">' +
                    '<button type="button" class="btn btn-hlc" id="guardarPreferencias">Guardar preferencias</button>' +
                    "</div></div></div>" +
                    "</div>"
                );
            } else {
                return (
                    '<div class="modal" id="cookiesModal" tabindex="-1" role="dialog" aria-hidden="true" style="overflow:auto;">' +
                    '<div class="modal-dialog" id="modalCookiesDialog" role="document">' +
                    '<div class="modal-content"><div class="modal-header"><h5 class="modal-title" id="exampleModalLabel">Customize cookies</h5>' +
                    '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>' +
                    '<div class="modal-body">' +
                    '<div class="form-check"><input class="form-check-input" type="checkbox" id="cookiesFuncionales" checked disabled /> <label style="color:inherit;" class="form-check-label" for="cookiesFuncionales">Functional Cookies</label>' +
                    '<p style="font-size:15px;">They are essential for you to use the website as intended. These cookies, for example, remember your language preferences. Without them, our website wouldn\'t function properly or provide the basic features you need.</p>' +
                    "</div>" +
                    '<div class="form-check"><input class="form-check-input" type="checkbox" id="cookiesAnaliticas" /> <label class="form-check-label" for="cookiesAnaliticas">Analytical Cookies</label>' +
                    '<p style="font-size:15px;">Are those that well treated by us or by third parties, allow us to quantify the number of users and thus perform the measurement and statistical analysis of the use made by users of the service offered. To do this, your browsing on our website is analyzed in order to improve the supply of products or services that we offer.</p>' +
                    "</div>" +
                    '<div class="form-check"><input class="form-check-input" type="checkbox" id="cookiesPubli" /> <label class="form-check-label" for="cookiesPubli">Advertising Cookies</label>' +
                    '<p style="font-size:15px;">These cookies are processed by third parties, which allow them to manage as effectively as possible the supply of advertising space on other websites, adapting the content of the advertisement to the content of the service requested or to the use you make of our website. To do this they can analyze your browsing habits on the Internet and can show you advertising related to your browsing profile.</p>' +
                    "</div>" +
                    '<div class="modal-footer">' +
                    '<button type="button" class="btn btn-hlc" id="guardarPreferencias">Save preferences</button>' +
                    "</div></div></div>" +
                    "</div>"
                );
            }
        },

        ////////////////////////////////////////

        ///////////////////////////////////////

        cookieSetter: null,
        cookieGetter: null,
        cookie: function () {
            var cookie = {
                nombre: "",
                valor: "",
            };
            return cookie;
        },
        deletedCookies: new Array(),
        deleteAllCookies: function () {
            var cookies = document.cookie.split(";");
            var parts = document.domain.split(".");
            var subdomain = parts.shift();
            var upperleveldomain = parts.join(".");
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i];
                var eqPos = cookie.indexOf("=");
                var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                name = name.trim();
                if (name != "cookies" && name != "wp-wpml_current_language") {
                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                    document.cookie = name + "=;domain=" + document.domain + ";expires=Thu, 01 Jan 1970 00:00:01 GMT;";
                    if (upperleveldomain != document.domain) {
                        document.cookie = name + "=;domain=" + upperleveldomain + ";expires=Thu, 01 Jan 1970 00:00:01 GMT;";
                    }
                }
            }
        },
        interval: 0,
        getCookie: function (c_name) {
            var c_value = document.cookie;
            var c_start = c_value.indexOf(" " + c_name + "=");
            if (c_start == -1) {
                c_start = c_value.indexOf(c_name + "=");
            }
            if (c_start == -1) {
                c_value = null;
            } else {
                c_start = c_value.indexOf("=", c_start) + 1;
                var c_end = c_value.indexOf(";", c_start);
                if (c_end == -1) {
                    c_end = c_value.length;
                }
                c_value = unescape(c_value.substring(c_start, c_end));
            }
            return c_value;
        },
        setCookie: function (c_name, value, exdays) {
            var exdate = new Date();
            exdate.setDate(exdate.getDate() + exdays);
            var c_value = decodeURIComponent(value) + (exdays == null ? "" : "; expires=" + exdate.toUTCString());
            document.cookie = c_name + "=" + c_value + "; path=/";
        },
        hasCookie: function () {
            var cookie = this.getCookie("cookies");
            return cookie == undefined || cookie == null || !cookie.includes(this.bannerInteractionTrue);
        },
        executeCookieRelatedActions: function (is_ads_allowed, is_analytics_allowed) {
            if (is_ads_allowed) {
                this.loadAdwordsScript();
            } else if (!is_analytics_allowed) {
                this.deleteAllCookies();
            }
        },
        writeAndExecuteCookieActions: function (is_ads_allowed, is_analytics_allowed) {
            var cookieValue = `${this.bannerInteractionTrue}, ad_storage:${is_ads_allowed}, ad_personalization:${is_ads_allowed}, ad_user_data:${is_ads_allowed}, analytics_storage:${is_analytics_allowed}`;
            document.body.classList.remove("no-scroll");
            jQuery("#cookies").remove();
            jQuery("#cookies-overlay").remove();
            this.setCookie("cookies", cookieValue, 365 * 100);
            dataLayer.push({
                event: "update_consent",
                analytics: is_analytics_allowed ? "granted" : "denied",
                ads: is_ads_allowed ? "granted" : "denied",
            });
            this.executeCookieRelatedActions(is_ads_allowed, is_analytics_allowed);
        },
        readAndExecuteCookieActions: function () {
            var cookieValue = this.getCookie("cookies");
            var is_ads_allowed = cookieValue.includes(this.acceptedAdsCookieFragment);
            var is_analytics_allowed = cookieValue.includes(this.acceptedAnalyticsCookieFragment);
            this.executeCookieRelatedActions(is_ads_allowed, is_analytics_allowed);
        },
        setDefaultCookies: function () {
            jQuery("#cookies").remove();
            this.setCookie("cookies", this.initialCookieValue(), 365 * 100);
            this.deleteAllCookies();
        },
        mostrarMensajeCookies: function (selector) {
            document.body.classList.add("no-scroll");
            jQuery("body").append(this.leyCookies() + this.modalCookies());
            jQuery("#cookies").slideDown(1000);

            jQuery("#aceptarCookies").click(function (e) {
                CookieManager.writeAndExecuteCookieActions(true, true);
                e.preventDefault();
            });

            jQuery("#cookiesModal").on("click", function (e) {
                if (e.target.id != "modalCookiesDialog" && !$(e.target).parents("#modalCookiesDialog").length) {
                    jQuery(".close").click();
                }
            });
            jQuery(".close").on("click", function (e) {
                jQuery(".modal-backdrop").remove();
                jQuery("#cookiesModal").hide();
            });

            jQuery("#configurarCookies").on("click", function (e) {
                jQuery("body").append('<div class="modal-backdrop fade show"></div>');
                jQuery("#cookiesModal").fadeIn();
            });

            jQuery("#guardarPreferencias").on("click", function (e) {
                CookieManager.writeAndExecuteCookieActions(jQuery("#cookiesPubli").is(":checked"), jQuery("#cookiesAnaliticas").is(":checked"));
                jQuery(".close").click();
                e.preventDefault();
            });

            jQuery("#rechazarTodas").click(function (e) {
                CookieManager.writeAndExecuteCookieActions(false, false);
                jQuery(".close").click();
                e.preventDefault();
            });

            this.deleteAllCookies();
        },
        loadAdwordsScript: function () {
            var script = document.createElement("script");
            script.src = "https://www.googletagmanager.com/gtag/js?id=AW-845797600";
            script.async = true;
            document.head.appendChild(script);
        },
        //se le pasa el selector donde va el mensaje
        init: function (idioma) {
            this.idioma = idioma;
            if (this.hasCookie()) {
                CookieManager.setDefaultCookies();
                this.mostrarMensajeCookies();
            } else {
                this.readAndExecuteCookieActions();
            }
        },
    };

    var SizeEventGenerator = function () {
        var $jsXL = $("#xl-detector");
        var isXL = null;

        updateXL();

        function updateXL() {
            var auxIsXL = $jsXL.css("display") == "block";
            if (auxIsXL != isXL) {
                if (auxIsXL) {
                    $("body").trigger("xlExit");
                } else {
                    $("body").trigger("xlEnter");
                }
                isXL = auxIsXL;
            }
        }

        $(window).resize(function () {
            updateXL();
        });
    };

    function changeLanguageMenuPosition() {
        $("body").on("xlEnter", function () {
            const languageMenu = document.querySelector(".section-header-language");
            const menuWrapper = document.querySelector(".section-header-right");

            menuWrapper.append(languageMenu);

            $(".wpml-ls-legacy-dropdown-click").css({
                width: "15.5em",
            });

            $("#masthead .section-header-language").css({
                "border-bottom": "none",
            });

            $(".wpml-ls-sub-menu").css({
                "border-bottom": "none",
                "border-top": "none",
            });
        });

        $("body").on("xlExit", function () {
            const languageMenu = document.querySelector(".section-header-language");
            const menuWrapper = document.querySelector("#menu-principal");

            const enlaceContacto = document.querySelector(".enlaceContactoMovil");
            enlaceContacto.after(languageMenu);

            $(".wpml-ls-legacy-dropdown-click").css({
                width: "100%",
            });
            $(".wpml-ls-sub-menu").css({
                background: "white",
                "border-bottom": "1px solid",
            });

            $(".wpml-ls-item").css({
                "border-bottom": "none",
            });

            $("#masthead .section-header-language").css({
                "border-bottom": "1px solid",
            });
        });
    }

    var NoCriticalJSLoaded = false;

    var LoadNoCriticalJS = function () {
        if (!NoCriticalJSLoaded) {
            import(
                /* webpackChunkName: "nocriticalJS" */
                /* webpackMode: "lazy" */
                "./nocritical"
            ).then((module) => {
                var uplinkInit = module.uplinkInit;
                var textoCopyright = module.textoCopyright;

                uplinkInit($);
                textoCopyright($);

                NoCriticalJSLoaded = true;
            });
        }
    };

    function disableSendButtonOnSend() {
        let $formButton = null;
        var modal = document.getElementById("ventanaModal");

        $(".wpcf7-form").on("submit", function () {
            $formButton = $(this).find(":input[type=submit]");
            $formButton.prop("disabled", true);

            if ($("#70a44fd").css("display") === "block") {
                $("#ventanaModal").css("display", "block");
            } else {
                $("#ventanaModal2").css("display", "block");
            }
        });

        document.addEventListener(
            "wpcf7submit",
            function (event) {
                if (event.detail.status == "validation_failed") {
                    $("#ventanaModal").css("display", "none");
                    $formButton.prop("disabled", false);
                }
            },
            false
        );

        document.addEventListener(
            "wpcf7mailsent",
            function (event) {
                const $Url = $(location).attr("href");

                const $version = document.getElementById("idioma").textContent;

                $(".wpcf7-response-output").css("display", "none");

                $("#ventanaModal").css("display", "none");
                $("#ventanaModal2").css("display", "none");

                if ($motivo_contacto === "rrhh") {
                    if ($version === "es") {
                        window.location.replace("/gracias-empleo");
                    } else {
                        window.location.replace("/en/thank-you-employment");
                    }
                } else {
                    if ($version === "es") {
                        window.location.replace("/gracias-peticion");
                    } else {
                        window.location.replace("/en/thank-you-request");
                    }
                }
            },
            false
        );
    }

    function buscarMotivoContacto() {
        $("#motivo-contacto").on("change", function () {
            $motivo = $("#motivo-contacto").children("option:selected").val();
            if ($motivo === "Empleo" || $motivo === "Employment") {
                $("#70a44fd").css("display", "none");
                $("#b728952").css("display", "block");
                if ($motivo === "Employment") {
                    $("#motivo-contacto option[value='Employment']").prop("selected", "selected");
                    $("#motivo-contacto2 option[value='Employment']").prop("selected", "selected");
                } else {
                    $("#motivo-contacto option[value='Empleo']").prop("selected", "selected");
                    $("#motivo-contacto2 option[value='Empleo']").prop("selected", "selected");
                }
                $motivo_contacto = "rrhh";
            } else {
                $("#70a44fd").css("display", "block");
                $("#b728952").css("display", "none");
                if ($motivo === "Commercial Information") {
                    $("#motivo-contacto option[value='Commercial Information']").prop("selected", "selected");
                    $("#motivo-contacto2 option[value='Commercial Information']").prop("selected", "selected");
                } else {
                    $("#motivo-contacto option[value='Información Comercial']").prop("selected", "selected");
                    $("#motivo-contacto2 option[value='Información Comercial']").prop("selected", "selected");
                }
                $motivo_contacto = "comercial";
            }
        });

        $("#motivo-contacto2").on("change", function () {
            $motivo = $("#motivo-contacto2").children("option:selected").val();
            if ($motivo === "Empleo" || $motivo === "Employment") {
                $("#70a44fd").css("display", "none");
                $("#b728952").css("display", "block");
                if ($motivo === "Employment") {
                    $("#motivo-contacto option[value='Employment']").prop("selected", "selected");
                    $("#motivo-contacto2 option[value='Employment']").prop("selected", "selected");
                } else {
                    $("#motivo-contacto option[value='Empleo']").prop("selected", "selected");
                    $("#motivo-contacto2 option[value='Empleo']").prop("selected", "selected");
                }
                $motivo_contacto = "rrhh";
            } else {
                $("#70a44fd").css("display", "block");
                $("#b728952").css("display", "none");
                if ($motivo === "Commercial Information") {
                    $("#motivo-contacto option[value='Commercial Information']").prop("selected", "selected");
                    $("#motivo-contacto2 option[value='Commercial Information']").prop("selected", "selected");
                } else {
                    $("#motivo-contacto option[value='Información Comercial']").prop("selected", "selected");
                    $("#motivo-contacto2 option[value='Información Comercial']").prop("selected", "selected");
                }
                $motivo_contacto = "comercial";
            }
        });
    }

    $(function () {
        const idioma = document.getElementById("idioma");
        if (idioma) {
            CookieManager.init(idioma.textContent);
        }

        $("#hl-search-text").on("keypress", function (e) {
            if (e.which == 13) {
                $("#hl-search-submit").click();
            }
        });

        $motivo_contacto = "comercial";

        buscarMotivoContacto();

        disableSendButtonOnSend();

        $(".wpml-ls-item-toggle").on("click", function () {
            const submenu = (visibilityValor = $(".wpml-ls-sub-menu").css("visibility"));
            console.log(submenu);

            if (submenu == "hidden") {
                $(".wpml-ls>ul li").css({
                    "border-bottom": "none",
                });
            }
        });

        const languageMenu = document.querySelector(".section-header-language");

        $("#burguer-check").on("click", function () {
            var $this = $(this);

            if (!$this.is(":checked")) {
                $("#menu-principal").removeClass("menu-abierto");
                $("#menu-principal").removeClass("tiene-sub-menu");

                $("#menu-principal-ingles").removeClass("menu-abierto");
                $("#menu-principal-ingles").removeClass("tiene-sub-menu");

                $(".es-sub-menu").addClass("sub-menu"); //restablece nivel 3
                $(".sub-menu").hide();
            } else {
                $("#menu-principal").addClass("menu-abierto");
                $("#menu-principal-ingles").addClass("menu-abierto");
                $("html,body").animate({ scrollTop: $("#menu").offset().top }, 500);
            }
        });
        $(".submenu-expand").on("click", function () {
            var $this = $(this);
            $this.next().show();
            var $padre = $this.parent().parent();
            //alert(window.innerWidth);

            if ($padre.attr("id") == "menu-principal" || $padre.attr("id") == "menu-principal-ingles") {
                //es nivel 2
                $padre.addClass("tiene-sub-menu");

                //const enlaceContacto = document.querySelector('.sub-menu');
                //enlaceContacto.append(languageMenu);

                if (window.innerWidth < 1200) {
                    $("#container-menu .sub-menu").each(function () {
                        var displayPropiedad = $(this).css("display");
                        console.log("Elemento:", $(this).text(), "Display:", displayPropiedad);
                        if (displayPropiedad == "block") {
                            //const enlaceContacto = document.querySelector('.sub-menu');
                            $(this).append(languageMenu);
                        }
                    });
                }
            } else {
                //es nivel 3
                $padre.addClass("es-sub-menu");
                $padre.removeClass("sub-menu");

                //const enlaceContacto = document.querySelector('.sub-menu');
                //enlaceContacto.append(languageMenu);
                if (window.innerWidth < 1200) {
                    $(".menu_nivel2 .sub-menu").each(function () {
                        var displayPropiedad = $(this).css("display");
                        console.log("Elemento:", $(this).text(), "Display:", displayPropiedad);
                        if (displayPropiedad == "block") {
                            //const enlaceContacto = document.querySelector('.sub-menu');
                            $(this).append(languageMenu);
                        }
                    });
                }
            }
        });
        $(".mobile-parent-nav-menu-item").on("click", function () {
            var $this = $(this);
            $this.parent().hide();

            var $padre = $this.parent().parent().parent();

            if ($padre.attr("id") == "menu-principal" || $padre.attr("id") == "menu-principal-ingles") {
                //es nivel 2
                $padre.removeClass("tiene-sub-menu");
                const enlaceContacto = document.querySelector(".enlaceContactoMovil");
                enlaceContacto.after(languageMenu);
            } else {
                //es nivel 3
                $padre.addClass("sub-menu");
                const enlaceContacto = document.querySelector(".sub-menu");
                enlaceContacto.append(languageMenu);
            }
        });

        $("body").on("xlEnter", function () {
            if ($("#menu-principal").hasClass("menu-abierto") || $("#menu-principal-ingles").hasClass("menu-abierto")) {
                $("#burguer-check").click();
            }
            $(".sub-menu").css("display", "");
        });

        $(".change-settings-button").on("click", function (e) {
            var $this = $(this);
            if (!$("#moove_gdpr_cookie_modal").length) {
                $.ajax({ url: "/gdpr.html" }).done(function (data) {
                    $("body").append(data);
                    $this.click();
                });
                e.preventDefault();
            }
        });

        if ($(window).scrollTop() > 0) {
            LoadNoCriticalJS();
        }

        if (!NoCriticalJSLoaded) {
            //en el primer movimiento de scroll cargo JavaScript adicional y desvinculo el evento
            $(window).bind("scroll", function () {
                //$(window).unbind('scroll');

                LoadNoCriticalJS();
            });
        }
        changeLanguageMenuPosition();
        SizeEventGenerator();

        $("button[data-link]").on("click", function (e) {
            window.location = $(this).data("link");
            e.preventDefault();
        });

        if ($(".mc4wp-response .mc4wp-alert.mc4wp-success").length > 0) {
            gtag("event", "conversion", {
                send_to: "AW-845797600/3seACP-763IQ4LGnkwM",
            });
            gtag("event", "pageview", {
                page_location: "/newsletter-ok.html",
                page_path: "/newsletter-ok.html",
                send_page_view: true,
            });
            // __gaTracker('send', 'pageview', '/newsletter-ok.html');
        }

        $(".bctt-click-to-tweet .bctt-ctt-text a").on("click", function (e) {
            var texto = $(this).html();
            gtag("send", "event", {
                event_label: document.title,
                event_category: "ClickToTweet",
                event_value: texto,
            });
            // __gaTracker('send', 'event', 'ClickToTweet', document.title,  texto);
        });
        $(".bctt-click-to-tweet > a").on("click", function (e) {
            var texto = $(this).parent().find(".bctt-ctt-text a").html();
            gtag("send", "event", {
                event_label: document.title,
                event_category: "ClickToTweet",
                event_value: texto,
            });
            // __gaTracker('send', 'event', 'ClickToTweet', document.title,  texto);
        });

        $('input[name="post_url"]').val(window.location.href);
        $('input[name="post_title"]').val(document.title);
    });

    $('input[name="post_url"]').val(window.location.href);
    $('input[name="post_title"]').val(document.title);

    // Esto es un apaño porque hay algo que borra estos datos en la carga inicial, TODO: descubrir que es y quitar estas líneas
    setTimeout(function () {
        $('input[name="post_url"]').val(window.location.href);
        $('input[name="post_title"]').val(document.title);
    }, 2000);
})(jQuery);
